import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import { StockType } from '@/constants/common';
import Rmb from '@/components/common/rmb/index.vue';
import FormatItem from '@/components/common/format-item/index.vue';
import AddressDetail from '@/components/address/address-detail/index.vue';
import { getDiscountPrice } from '@/utils/common';
import { wxPay } from '@/utils/wx-promise';
import { getToken } from '@/utils/localStorage.js';
import { postActSubmit, payMarketingOrder } from '@/service/enroll-activity/index';

async function alert({ message }) {
    Toast(message);
}

/**
 * 下单页面
 */
export default {
    name: 'pre-order',

    components: {
        Rmb,
        AddressDetail,
        FormatItem,
    },

    data() {
        return {
            express: {},
            showExpressPicker: false,
            showOrderPicker: false,
            from: null, // 来源页面
            validCouponCount: 0,
            editAddressItem: {},
            expresslist: [],
            choseType: 0, // 选择支付的类型,0为打款凭证，1为余额支付
            cloudOrderProduct: {}, // 将要放入云仓库产品
            rules: [
                {
                    message: '您还没有选择下单地址',
                    validator() {
                        return this.subOrders[0] && this.subOrders[0].address && !this.subOrders[0].address.id;
                    },
                    anchor: '.address-empty',
                },
                {
                    message: '您还没有选择快递物流',
                    validator() {
                        return !this.express.text;
                    },
                    anchor: '.express',
                },
            ],
            code: '', // 为支付成功但是已经提交订单
        };
    },

    computed: {
        ...mapState({
            myAddresses: state => state.user.addresses,
            userId: state => state.user.user.id,
            userRank: state =>
                state.user.user.levelall && state.user.user.levelall[0] && state.user.user.levelall[0].rank,
            order: state => state.preorder, // 预订单
            cart: state => state.cart.cart[StockType.Sell],
            brandId: state => state.global.brandId,
            subOrders: state => state.memory.subOrders, // 子订单
            showReceipt: state => state.permission.permission.order.receipt,
        }),

        // 总价格（分）
        systemTotalPrice() {
            if (this.order.products.length !== 0) {
                let exp = {};
                this.order.products.map(p => {
                    exp[p.systemId] = 0;
                    p.carts.map(cart => {
                        exp[p.systemId] =
                            exp[p.systemId] +
                            getDiscountPrice(JSON.parse(cart.discount), cart.userRank, cart.price) * cart.quantity;
                    });
                });
                return exp;
            }
        },

        // 优惠券总价
        totalCouponsPrice() {
            let money = 0;
            if (this.order.coupons) {
                this.order.coupons.forEach(item => {
                    money += item.coupon.discount;
                });
            }
            return money;
        },
    },

    methods: {
        getDiscountPrice,

        // 选择快递
        onConfirm(data) {
            this.express = data;
            this.showExpressPicker = false;
        },

        // 选择地址
        onChooseAddress() {
            this.$router.push({
                name: 'address-list',
                query: {
                    from: 'pre-order',
                    addressId: this.subOrders[0].address.id,
                },
            });
        },

        // 初始化
        async init() {
            // 设置地址
            let selectedAddress = this.order.address;
            await this.$store.dispatch('user/getAddresses');
            if (selectedAddress && selectedAddress.id) {
                selectedAddress = this.myAddresses.find(item => item.id === selectedAddress.id); // 更新。因为有可能删除/修改了
            }
            if (!selectedAddress || !selectedAddress.id) {
                selectedAddress = this.myAddresses.find(item => item.isDefault); // 如果没有，就拿默认地址
            }

            this.$store.commit('memory/setSubOrders', [
                {
                    address: selectedAddress || {},
                    products: this.order.products,
                    remark: '',
                },
            ]);
        },

        // 获取所有的物流信息
        async getexpresslist() {
            try {
                let _expresslist = await this.$http1.get(`expresses/sold`);
                _expresslist = _expresslist.filter(ex => ex.name === '德邦物流');
                _expresslist.forEach(elment => {
                    elment.text = elment.name;
                    elment.value = elment.com;
                });
                this.expresslist = _expresslist;
            } catch (e) {}
        },

        // 封装 productList 对象
        _wrapProductList(subOrders) {
            let products = this.subOrders[0].products;
            const newItem = {};
            newItem.productId = products.productId;
            newItem.formatId = products.formatId;
            newItem.quantity = products.quantity;
            newItem.unit = products.unit;
            newItem.thumb = products.thumb;
            newItem.productName = products.productName;
            newItem.price = products.price;
            newItem.places = products.places;
            newItem.countPrice = products.products;
            return [newItem];
        },

        // 计算完整地址
        wrapFullAddress(address) {
            const { region, detail } = address;
            if (region && detail) {
                return region.city.province.name + ' ' + region.city.name + ' ' + region.name + ' ' + detail;
            }
            if (region && (!detail || detail === '')) {
                return region.city.province.name + ' ' + region.city.name + ' ' + region.name;
            }
            if (!region && detail) {
                return detail;
            }
        },

        // 封装 addressInfo 对象
        _wrapAddressInfo(subOrders) {
            let address = this.subOrders[0].address;
            const newItem = {};
            newItem.userName = address.name;
            newItem.phone = address.phone;
            newItem.regionId = address.regionId;
            newItem.detail = address.detail;
            newItem.fullAddress = this.wrapFullAddress(address);
            return newItem;
        },

        // 确认下单
        async payNow() {
            // 参数校验
            for (const rule of this.rules) {
                const { message, validator, anchor } = rule;
                if (validator.call(this)) {
                    // 不通过
                    Toast(message);
                    const $element = document.querySelector(anchor);
                    if ($element) {
                        $element.scrollIntoView({
                            behavior: 'smooth',
                            block: 'start',
                            inline: 'nearest',
                        });
                    }
                    return;
                }
            }

            try {
                let message = '';
                let products = this.subOrders[0].products;
                // this.subOrders[0].products.forEach(product => {
                //     message += `<p><span class="name">${product.name}</span><span class="value">x ${product.count}</span></p>`
                // })
                message += `<p><span class="name">${products.productName}</span><span class="value">x ${products.quantity}</span></p>`;
                message += `<p><span class="name">总计</span><span class="value">￥${this.$fen2Yuan(
                    this.order.totalPrice
                )}</span></p>`;
                await Dialog.confirm({
                    message,
                    title: '产品清单',
                });
                // 下单
                let _params = {
                    actId: this.$route.query.id,
                    userId: this.userId,
                    trackCompany: this.express.text || '',
                    remark: this.order.remark,
                    systemId: this.order.products.systemId,
                    totalPrice: this.order.totalPrice,
                    addressInfo: this._wrapAddressInfo(this.subOrders[0]),
                    productList: this._wrapProductList(this.order.products),
                };

                if (!this.code) {
                    let data = await postActSubmit(_params);
                    this.code = data;
                }

                if (this.order.totalPrice === 0) {
                    this.$router.replace({
                        name: 'pay-success',
                        query: {
                            type: StockType.Sell,
                        },
                    });

                    // 重置预订单
                    this.$store.dispatch('preorder/reset');
                    return;
                }

                payMarketingOrder({ code: this.code, token: getToken() }).then(res => {
                    // 调用内置微信支付方法  发起支付
                    const payParams = {
                        appId: res.appId,
                        timeStamp: res.timeStamp,
                        nonceStr: res.nonceStr,
                        package: res.packageValue,
                        signType: res.signType,
                        paySign: res.paySign,
                    };
                    wxPay(payParams).then(wxRes => {
                        this.$router.replace({
                            name: 'pay-success',
                            query: {
                                type: StockType.Sell,
                            },
                        });
                        // 重置预订单
                        this.$store.dispatch('preorder/reset');
                    });
                });
            } catch (e) {
                console.error(e);
            }
        },
    },

    async created() {
        this.from = this.$route.query.from;
        await this.init();
        await this.getexpresslist();
    },

    async activated() {
        await this.init();
    },
};
